import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { InfoBox } from "./InfoBox";
import { ReactComponent as PartisiaIcon } from "./icons/partisiablockchain.svg";
import { ReactComponent as ZenIcon } from "./icons/zen.svg";

import bg from "./assets/bg.webp";

library.add(fas, far);

function App() {
  return (
    <div className="inset-0 absolute bg-cover overflow-y-visible overflow-x-hidden">
      <div
        className="inset-0 fixed bg-cover overflow-y-visible overflow-x-hidden -z-10 contrast-125 brightness-95 saturate-100"
        style={{ backgroundImage: `url(${bg})` }}
      ></div>
      <div className="text-center py-5">
        <ZenIcon className="w-24 h-24 fill-emerald-800 mx-auto" />
        <h1 className="text-4xl text-emerald-900 font-semibold pt-2 tracking-widest uppercase">
          Zen Pools
        </h1>
      </div>
      <div className="mx-auto bg-emerald-800">
        <div className="p-4 grid md:grid-cols-3 sm:grid-cols-2 gap-4 max-w-4xl mx-auto">
          <InfoBox
            icon="heart-pulse"
            title="Stable"
            description="Be at ease while earning passive income. Our nodes are monitored and backups are in place."
          />
          <InfoBox
            icon="leaf"
            title="Eco-Friendly"
            description="Take care of our environment. We will donate to keep our carbon footprint near-zero."
          />
          <InfoBox
            icon="lock"
            title="Secure"
            description="We use state-of-the-art technologies from leading Cloud providers to keep your funds safe."
          />
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-4 mb-4">
        <h2 className="text-4xl	text-center text-emerald-900 py-6">Pools</h2>
        <div className="grid md:grid-cols-2 gap-6 h-full">
          <div className="rounded-lg bg-emerald-800 p-4 text-white">
            <PartisiaIcon
              className="w-12 h-12 mx-auto"
              title="Partisia Blockchain"
            />
            <div className="p-2 uppercase tracking-wide text-sm text-center font-semibold">
              Partisia Blockchain
            </div>
            <p className="text-sm">
              Stake with us to help secure the network. Put your money to work,
              sit back, and enjoy your passive income.
            </p>
            <p className="font-semibold pt-2 text-center text-gray-100">
              More information to come...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
