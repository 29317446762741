import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  icon: IconProp;
  title: string;
  description: string;
}

export const InfoBox = ({ icon, title, description }: Props) => {
  return (
    <div className="bg-white rounded-lg border border-emerald-900 overflow-hidden p-4">
      <div className="w-12 h-12 mx-auto">
        <FontAwesomeIcon
          icon={icon}
          className="w-full h-full text-emerald-900"
        />
      </div>
      <div className="p-2 uppercase tracking-wide text-sm text-emerald-900 text-center font-semibold">
        {title}
      </div>
      <div className="tracking-wide text-sm font-semibold">{description}</div>
    </div>
  );
};
